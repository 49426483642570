import window from 'global';
import * as React from 'react';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';
import Head from '../components/Head';
import MainTitle from '../components/MainTitle';
import MobileNavbar from '../components/MobileNav';
import Navbar from '../components/NavBar';
import Section from '../components/Section';
import SectionContentContainer from '../components/SectionContentContainer';
import contactUsBgImg from '../images/contact-us.webp';

const ContactUsPage = () => {
  const breakpoint = 991;
  const [dimension, setDimension] = React.useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  React.useEffect(() => {
    function handleResize() {
      setDimension({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }
    window.addEventListener('resize', handleResize);
  }, []);

  return (
    <>
      <Head pageTitle="Contact Us" />
      {dimension.width > breakpoint ? <Navbar /> : <MobileNavbar />}
      <Section backgroundImage={contactUsBgImg} customStyle={{ textAlign: 'center' }}>
        {dimension.width > breakpoint ? (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="CONTACT US" style={{ fontSize: 'calc(3vw + 3vh)' }} />
          </SectionContentContainer>
        ) : (
          <SectionContentContainer customStyle={{ left: 0 }}>
            <MainTitle text="CONTACT US" style={{ fontSize: 'calc(4vw + 4vh)' }} />
          </SectionContentContainer>
        )}
      </Section>
      {dimension.width > breakpoint ? (
        <Section customStyle={{ backgroundColor: 'black', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5% 35%',
          }}
          >
            <ContactForm formTitle="REACH OUT TO US FOR FURTHER ADVICE" />
          </SectionContentContainer>
        </Section>
      ) : (
        <Section customStyle={{ backgroundColor: 'black', textAlign: 'center' }}>
          <SectionContentContainer customStyle={{
            position: 'relative', left: 0, padding: '5%',
          }}
          >
            <ContactForm formTitle="REACH OUT TO US FOR FURTHER ADVICE" />
          </SectionContentContainer>
        </Section>
      )}
      <Footer />
    </>
  );
};

export default ContactUsPage;
